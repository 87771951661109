import React from "react";

function Art() {
  return (
    <div>
      <h1>Hoots Inspired Art & Entertainment</h1>
      <a
        href="https://memory.doofies.xyz"
        target="_blank"
        rel="noopener noreferrer"
      >
        Memory Hoots Card Game
      </a>
    </div>
  );
}

export default Art;
